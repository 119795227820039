import React, { Component } from 'react';
import Masonry from 'react-masonry-css';
import { breakpoints } from '../Styles/index';

const breakpointColumns = {
    default: 4,
    [breakpoints.desktop]: 3,
    [breakpoints.tablet]: 2,
    480: 1,
};

export default class ProductGallery extends Component {
    render() {
        return (
            <Masonry
                breakpointCols={breakpointColumns}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {this.props.children}
            </Masonry>
        );
    }
}
