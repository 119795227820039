import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../Layouts/';
import ProductCard from '../components/ProductCard';
import MasonryGrid from '../components/MasonryGrid';
import Pagination from 'react-bulma-components/lib/components/pagination';

export default class Shop extends Component {
    state = {
        page: 1,
    };

    handleOnChange = page => {
        const pathPrefix = 'shop';
        navigate(`/${pathPrefix}/${page === 1 ? '' : page}`, {
            state: { page },
        });
        this.setState({
            page,
        });
    };

    render() {
        console.log(this.props);
        const { products } = this.props.data;
        const { numberOfPages, humanPageNumber } = this.props.pageContext;
        return (
            <Layout page_layout="full-width-content">
                <MasonryGrid>
                    {products.edges.map(edge => {
                        let product = edge.node;
                        return (
                            <ProductCard key={product.id} product={product} />
                        );
                    })}
                </MasonryGrid>
                <Pagination
                    current={humanPageNumber}
                    total={numberOfPages}
                    onChange={this.handleOnChange}
                />
            </Layout>
        );
    }
}

export const pageQuery = graphql`
    query($skip: Int!, $limit: Int!) {
        products: allWordpressWcProducts(skip: $skip, limit: $limit) {
            ...products
        }
    }
`;
